import { graphql } from 'gatsby';
import React from 'react';

import { Layout } from '../containers/layout';
import { CareersTemplate } from '../pageTemplates/careers';

interface CareersProps {
  data: GraphQLData;
}

const Careers = ({ data }: CareersProps) => {
  return (
    <Layout footer={{ onlyNav: true }}>
      <CareersTemplate data={data} />
    </Layout>
  );
};

export const query = graphql`
  query Careers {
    strapi {
      career {
        subheader
        meta {
          title
          description
        }
      }
    }
  }
`;

export default Careers;
